import { EventEmitter, Injectable } from '@angular/core';
import { DataService, IResponseMessage, IToken, ITokenResponseMessage } from '../data.service';
import { Paths } from '../core/apis/paths';
import { IAccount } from '../core/interfaces/account';
import { IUser } from '../core/interfaces/Users';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public showMessageBar = new EventEmitter<string>();
  public isLoading = new EventEmitter<boolean>();

  constructor(
    private dataService: DataService
  ) { }

  /**
   * Make login request
   * @param credentials { username: string, password: string }
   * @returns Promise<IToken>
   */
  public login(credentials: { email: string, password: string }): Promise<IToken> {
    return this.dataService.login(credentials).toPromise();
  }

  /**
   * Make recovery password request
   * @param recoveryObject { email: string }
   * @returns Promise<IResponseMessage>
   */
  // public recoveryPassword(recoveryObject: { email: string }): Promise<IResponseMessage> {
  //   return this.dataService.recoverPassword(recoveryObject).toPromise();
  // }

  /**
   * Make change password
   * @param passwordObject { token: string, password: string }
   */
  public changePassword(passwordObject: { token: string, password: string }): Promise<ITokenResponseMessage> {
    return this.dataService.resetPassword(passwordObject).toPromise();
  }

  /**
   * Check if the token is valid
   * @param token string
   */
  public checkToken(token: string): Promise<IResponseMessage> {
    return this.dataService.checkToken(token).toPromise();
  }



  /**
   * Check if password is already being used by the current user
   */
  public checkPass(password: string): Promise<any> {
    const path = '/auth/check_pass';
    return this.dataService.post(path, { password }).toPromise();
  }

  /**
   * Make request in order to change password
   */
  public changePass(data: { actual_password: string, new_password: string }): Promise<{ msg: string, token: string }> {
    const path = '/auth/change_pass';
    return this.dataService.post(path, data).toPromise();
  }

  /**
  * Makes request in order to create user
  * @param user : IUser
  * @returns Promise<any>
  */
  createUser(user: IUser): Promise<any> {
    return this.dataService.unsecuredPost(Paths.user, user).toPromise();
  }

  /**
   * Makes request in order to update user by Id
   * @param userId : number
   * @param user : IUser
   * @returns Promise<any>
   */
  updateUser(userId: number, user: IUser): Promise<any> {
    const path = `${Paths.user}/${userId}`;
    return this.dataService.put(path, user).toPromise();
  }


  /**
   * Makes request in order to update user by Id
   * @param userId : number
   * @param user : IUser
   * @returns Promise<any>
   */
  updateUserImage(image: string): Promise<any> {
    const path = `${Paths.updateImage}`;
    return this.dataService.put(path, image).toPromise();
  }

  /**
  * Make request to get a user by token
  */
  public getUserByToken(): Promise<IAccount> {
    return this.dataService.get(`${Paths.fullauth}`).toPromise();
  }


  public logout(): Promise<any> {
    return this.dataService.delete(`${Paths.logout}`).toPromise();
  }

  /**
   * Show message between components father and child
   * @param v : string
   * @returns : void
   */
  public showMessage(v: string) {
    this.showMessageBar.emit(v);
  }

  /**
   * Emit an event when the child is loading
   * @param v : boolean
   */
  public loading(v: boolean) {
    this.isLoading.emit(v);
  }
}

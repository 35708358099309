import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './core/interfaces/Loader';
import { IUser } from './core/interfaces/Users';

@Injectable()
export class SessionService {

  private loaderSubject = new Subject<LoaderState>();
  private loaderUser = new Subject<IUser>();
  private loaderNotificationOrderId = new Subject<number>();
  private loaderHeader = new Subject<string>();
  private loaderTerm = new Subject<number>();
  private loaderHideTerm = new Subject<boolean>();

  private termActivate = false;

  loaderState = this.loaderSubject.asObservable();
  userState = this.loaderUser.asObservable();
  notificationOrderState = this.loaderNotificationOrderId.asObservable();
  headerState = this.loaderHeader.asObservable();
  termState = this.loaderTerm.asObservable();
  termHideState = this.loaderHideTerm.asObservable();
  public set session(v) {
  }

  public removeSession() {
    localStorage.clear();
  }

  showLoader() {
    this.loaderSubject.next(<LoaderState>{ show: true, await: false });
  }
  hideLoader() {
    this.loaderSubject.next(<LoaderState>{ show: false, await: false });
  }

  changeUserData(_user: any) {

    this.loaderUser.next(_user);
  }

  changeNotificationOrderData(order_id: number) {
    this.loaderNotificationOrderId.next(order_id);
  }

  changeHeaderData(type: string) {
    this.loaderHeader.next(type);
  }

  showTermsBard() {
    this.loaderTerm.next(60);
    this.termActivate = true;
  }

  hideTermsBard() {
    this.loaderTerm.next(-60);
    this.termActivate = false;
  }

  hideExternTerms() {
    this.loaderHideTerm.next(true);
  }

  changeTermValue(value: boolean) {
    this.termActivate = value;
  }

  getTermValue(): boolean {
    return this.termActivate;
  }


}

export interface IToken {
  token: string;
}

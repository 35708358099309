import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';
import { DataService } from '../data.service';
import { Paths } from '../core/apis/paths';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    private session: SessionService,
    private dataService: DataService,
    private cookieService: CookieService

  ) { }

  /**
   * Logout user
   */
  logout() {
    this.session.removeSession();
  }



  // Permissions.....
  getPermissions(params: any = null): Promise<{ permission: string[] }> {
    return this.dataService.get(Paths.permission, params).toPromise();
  }

  getPermissionsByName(name: string, params: any = null): Promise<{ exist: boolean }> {
    return this.dataService.get(`${Paths.permission}/${name}`, params).toPromise();
  }

  postPermissionsByArrayName(body: { permission: string[] }): Promise<any> {
    return this.dataService.post(`${Paths.permission}/multi`, body).toPromise();
  }





  // pager helper service
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10, pagerSize: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    totalPages = totalPages < 1? 1: totalPages;
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= pagerSize) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      let pagerFloorBetweenTwo = Math.floor(pagerSize/2);
      if (currentPage <= pagerFloorBetweenTwo+1 ) {
        startPage = 1;
        endPage = pagerSize;
      } else if (currentPage + pagerFloorBetweenTwo-1 >= totalPages) {
        startPage = totalPages - pagerSize + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - pagerFloorBetweenTwo;
        endPage = currentPage + Math.floor((pagerSize-1)/2);
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input() title = '';
  @Input() backgroundImage;

  @Output() filterCancelClicked = new EventEmitter();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedFilters: any[] = [];

  constructor() { }

  ngOnInit() {
  }

  add(name: string, value: string): void {
    const result = this.selectedFilters.filter(obj => {
      return obj.value === value;
    });
    if (result.length > 0) { return; }


    if (value) {
      this.selectedFilters.push({
        name: name,
        value: value
      });
    }
  }

  remove(filter: any): void {

    const index = this.selectedFilters.indexOf(filter);

    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
      this.filterCancelClicked.emit({
        filter
      });
    }
  }

  public getImgBackGround(): { background: string } {
    if(!this.backgroundImage) {
      return;
    }
    return { background: `url( ${this.backgroundImage} ) center center/cover no-repeat` };
  }

}

<div class="breadcrumbs-container">
    <div class="container">
        <section class="breadcrumbs">
            <div class="breadcrumbs-item" *ngFor="let item of breadcrumbItems; let i = index">
                <a *ngIf="(i < breadcrumbItems.length - 1)" [ngClass]="{ 'cursor-default':  item.title === ''}"
                    (click)="goTo(item)">{{item.title}} <i class="fad fa-chevron-right"></i></a>
                <a class="last" *ngIf="i === breadcrumbItems.length - 1"
                    [ngClass]="{ 'cursor-default':  item.title !== ''}">{{item.title}}</a>
            </div>
        </section>
    </div>
</div>
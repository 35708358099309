<div class="bread-crumb">
    <div class="bread-crumb-dialog">
        <app-breadcrumb-dialog [breadcrumbItems]="breadCrumbsParams"></app-breadcrumb-dialog>
    </div>
</div>

<div class="dialog-form">
    <form (ngSubmit)="rejectAndSendComments()" novalidate>
        <div class="dialog-form-header">
            <div class="dialog-container">
                <div class="dialog-column pad-sm">
                    <div class="dialog-title">
                        <h1>{{dialogTitle}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-column pad-sm">
            <div class="dialog-section">
                <div class="dialog-column pad-sm">
                    <div class="dialog-row pad-xs">
                        <!-- dialog-listing start -->
                        <section class="dialog-listing">
                            <table>
                                <!-- dempty state start -->
                                <tbody>
                                    <div class="container">
                                        <div class="dempty-state">
                                            <div class="dempty-state-icon">
                                                <button class="btn btn-xl {{dialogClass}}-a">
                                                    <div class="btn-xl-icon">
                                                        <i class="fad fa-waveform icon-xl"></i>
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="dempty-state-text">
                                                <p class="assistant-comment"><b>Asistente:</b> {{bodyMessage}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </tbody>
                                <!-- dempty state end -->
                            </table>
                        </section>
                        <!-- dialog-listing end-->
                    </div>
                    <div class="dialog-row pad-xs">

                    </div>
                    <div class="dialog-row pad-xs">
                        <div class="dialog-container">
                            <div class="dialog-input dialog-input-full">
                                <label for="reject-comment">Motivo del rechazo/cancelación</label>
                                <!-- text input start -->
                                <div class="input input-md dinput-gray  "
                                    [ngClass]="{'input-warn-light': commentControl.invalid && this.commentControl.touched  }">
                                    <div class="input-md-text">
                                        <input [formControl]="commentControl"
                                            placeholder="Escriba aquí el motivo del rechazo/cancelación"
                                            autocomplete="off" />
                                    </div>
                                </div>
                                <!-- text input end -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-footer">
            <div class="dialog-container">
                <div class="dialog-toolbar">
                    <button type="button" class="btn btn-md btn-secondary" mat-dialog-close>
                        <div class="btn-md-text">Cancelar</div>
                    </button>
                    <button class="btn btn-md {{dialogClass}}" type="submit">
                        <div class="btn-md-icon" *ngIf="!showButtonSaveLoader">
                            <i class="fal {{buttonIcon}} icon-md"></i>
                        </div>
                        <div class="btn-md-icon" *ngIf="showButtonSaveLoader">
                            <i class="fal fa-circle-notch spinner-danger icon-md"></i>
                        </div>
                        <div class="btn-md-text right">{{confirmText}}</div>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="header-fix"></div>
<header class="header">

    <div class="container">
        <div class="header-logo" [routerLink]="['/']" routerLinkActive="router-link-active">
            <img src="../../../../assets/img/logo-backoffice.png" height="40">
        </div>
        <div class="header-menu">
            <a class="header-menu-item">
                <i class="fad fa-analytics"></i>
                Analíticos
            </a>
            <a class="header-menu-item" [routerLink]="['/administration']" routerLinkActive="router-link-active">
                <i class="fad fa-tasks-alt"></i>
                Tiendas
            </a>
            <a class="header-menu-item">
                <i class="fad fa-megaphone"></i>
                Marketing
            </a>
            <a class="header-menu-item" [routerLink]="['/subscription-plan']" routerLinkActive="router-link-active">
                <i class="fad fa-th-list"></i>
                Subscripciones
            </a>
            <a class="header-menu-item">
                <i class="fad fa-sitemap"></i>
                Sitios
            </a>
            <a class="header-menu-item" [routerLink]="['/verification']" routerLinkActive="router-link-active"
                *ngIf="permission.back_office_businesses_list">
                <i class="fad fa-exchange"></i>
                Verificaciones
            </a>
            <a class="header-menu-item" [routerLink]="['/collections']" routerLinkActive="router-link-active">
                <i class="fad fa-exchange"></i>
                Colecciones
            </a>
            <a class="header-menu-item" [routerLink]="['/develop']" routerLinkActive="router-link-active">
                <i class="fad fa-code"></i>
                Desarrollo
            </a>
            <a class="header-menu-item" [routerLink]="['/sellers-payment']" routerLinkActive="router-link-active">
                <i class="fad fa-code"></i>
                Pago a vendedores
            </a>
        </div>

        <div class="header-user">
            <div *ngIf="user" class="header-user-card">
                <div class="user-profile-img" [ngStyle]="getImgBackGround()" *ngIf="user?.name"></div>
                <div class="user-info">
                    <p><strong>{{ user?.name }}</strong></p>
                    <p>{{ user?.company_name }}</p>
                </div>
                <a class="user-info-arrow" (click)="logout()"><i class="fad fa-sign-out-alt"></i></a>
            </div>

        </div>
    </div>
</header>
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-filter',
  templateUrl: './breadcrumb-filter.component.html',
  styleUrls: ['./breadcrumb-filter.component.scss']
})
export class BreadcrumbFilterComponent implements OnInit, OnDestroy {
  @Input() breadcrumbItems: IBreadcrumbFilter[];
  public breadcrumbs: string;
  private subscription: Subscription;

  constructor(
    private router: Router,
  ) {
    // this.subscribreRoute();
  }

  /**
   * Subscribe the route in order to get the title
   */
  subscribreRoute() {
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root)
          .join('-');
        this.breadcrumbs = title;
      }
    });
  }

  /**
   * Get the state title.
   * In order to check the example link
   * @param state
   * @param parent
   * @link https://stackoverflow.com/questions/38644314/changing-the-page-title-using-the-angular-2-new-router
   */
  private getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  goTo(url_link: IBreadcrumbFilter) {

    this.router.navigate([url_link.url], {queryParams: url_link.query});

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

}

export interface IBreadcrumbFilter {
  title: string;
  url?: string;
  query?: any;
}

export enum Paths {
    // login = '/auth/login',
    login = '/auth/back_office/login',

    // auth = '/auth/whoami',
    auth = '/back_office/auth/whoami',

    // fullauth = '/auth/whoami/full',
    fullauth = '/back_office/auth/whoami/full',

    // logout = '/auth/logout',
    logout = '/auth/back_office/logout',

    // checkToken = '/auth/check_reset',
    checkToken = '/auth/back_office/check_reset/',

    // changePassword = '/auth/reset',
    changePassword = '/auth/back_office/reset',

    updateImage = '/auth/back_office/update_image',
    user = '/back_office/user',

    permission = '/back_office/permission',
    permissionRole = '/back_office/permission/role',

    userTosAccept = '/user/tos/accept',
    uploadFile = '/back_office/upload',

    /**
     * New backofice endpoints
     */
    //Old upload endpoint
    //uploadBatchFile = '/back_office_batch/upload_scatter_file',
    //New upload endpoint
    uploadBatchFile = '/back_office_transaction/upload_file',
    batchDownloadTemplate = '/back_office_batch/download_template',
    batchDownloadTemplateList = '/back_office_batch/download_template/list',
    bachtUploadScatterFile = '/back_office_batch/upload_scatter_file',
    batchList = '/back_office_batch/list',
    bachDetailList = '/back_office_batch/detail',
    transaction = '/back_office_transaction',
    organizationsList = '/organizations/list',
    verify = '/back_office/verify',
    verifyReject = '/verify/reject',
    verifyDownloadFiles = '/verify/download_files',
    backOfficeBusinessesList = '/back_office/businesses/list',
    bussinessSeller = '/verify/organization_type',
    // get filter buyers
    transactionsBuyers = '/back_office_transaction/buyers/',
    // get filter sellers
    transactionsSellers = '/back_office_transaction/sellers/',
    // get filtes statuses
    transactionsStatuses = '/back_office_transaction/status',

    // get all stores list
    storesList = '/back_office_store',
    // verify store
    storeVerify = '/back_office_store/verify',
    billingSubscription = '/back_office/billing_subscription',
    // ban store visibility
    storeVisualize = '/back_office_store/visibility',
    //get stores files
    storeFilesOnZip = '/back_office_store/download_zip_file',
    taxSystem = '/verify/tax_system/list',
    verifyUpdate = '/verify/tax_system/update',
    planComission = '/back_office/plan_commission',
    cropsList = '/backoffice/crop_technique/list',
    //-------------------------------- Payments --------------------------------

    orderPayments = '/back_office/order_payments/list',
    putPaid = '/back_office/order_payment/pay',
    sellersAutocomplete = '/back_office/order_payment/seller',
    buyersAutocomplete = '/back_office/order_payment/buyer',
    orderPaymentsPayCsv = '/back_office/order_payments/pay_csv',
    //-------------------------------- Subscriptions ------------------------
    editPlan = "/back_office/payment_log/assign_plan",
    plansList = "/back_office/plan/list",
    priceTabulator = "/back_office/plan_price_tabulation_table",
    croppingTechniqueList = "/backoffice/crop_technique/list",
    priceTabulatorNew = "/back_office/plan_price_tabulation_table/new",
    //-------------------------------- Collections ------------------------
    collections = "/back_office_storefront/collection",
    slide = "/back_office_storefront/collection"
}

<div class="example-header">
    <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')"
        matTooltip=" {{(canPrevClicked('year')) ? 'Año anterior' :  ''}}"
        [disabled]="(canPrevClicked('year')) ? null : 'disabled'">
        <mat-icon><i class="fad fa-chevron-double-left"></i></mat-icon>
    </button>
    <button mat-icon-button (click)="previousClicked('month')"
        matTooltip=" {{(canPrevClicked('month')) ? 'Mes anterior' :  ''}}"
        [disabled]="(canPrevClicked('month')) ? null : 'disabled'">
        <mat-icon><i class="far fa-chevron-left"></i></mat-icon>
    </button>
    <span class="example-header-label" matTooltip="Fecha actual">{{periodLabel}}</span>
    <button mat-icon-button (click)="nextClicked('month')"
        matTooltip=" {{(canNextClicked('month')) ? 'Mes siguiente' :  ''}}"
        [disabled]="(canNextClicked('month')) ? null : 'disabled'">
        <mat-icon><i class="far fa-chevron-right"></i></mat-icon>
    </button>
    <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')"
        matTooltip="{{ (canNextClicked('year')) ? 'Año siguiente' :  ''}}"
        [disabled]="(canNextClicked('year')) ? null : 'disabled'">
        <mat-icon><i class="fad fa-chevron-double-right"></i></mat-icon>
    </button>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsToK'
})
export class ThousandsToKPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    value = Number(value); // Safety parse
    return value > 1000 ? `${(value / 1000)}K` : value;
  }

}

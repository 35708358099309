import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CanDeactivateGuard } from './canDeactivate.guard';
import localeEsMXN from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { ConnectionErrorComponent } from './pages/connection-error/connection-error.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { SessionService } from './session.service';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localeEsMXN, 'es-Mx');

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    ConnectionErrorComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatSnackBarModule,
    HttpClientModule,
    CoreModule,
  ],
  providers: [
    CookieService,
    SessionService,
    Title,
    CanDeactivateGuard,
    { provide: LOCALE_ID, useValue: 'es-Mx' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

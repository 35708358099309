import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map } from 'rxjs/operators';
declare let gtag: Function;
declare let fbq: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'agricompra-new';
  mySubscription;
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    setInterval(() => {
      if (!navigator.onLine) {
        router.navigate(['/connection-error']);
      }
    }, 1000);
  }
  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }


  ngOnInit() {
    this.router.events.pipe(map(() => {
      let child = this.activatedRoute.firstChild;
      while (child) {
        if (child.firstChild) {
          child = child.firstChild;
        } else
          if (child.snapshot) {
            if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
      }
      return null;
    })).subscribe(title => {
      this.titleService.setTitle(title);
    });

  }
}


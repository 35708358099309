import { Pipe, PipeTransform } from '@angular/core';
const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',];
@Pipe({
    name: 'prettyDate'
})
export class PrettyDatePipe implements PipeTransform {

    transform(value: string, args?: any): any {
        const dueDate = new Date(value);
        return dueDate.toLocaleString('es-MX', { day: 'numeric', month: 'long', year: 'numeric', minute: '2-digit', hour: '2-digit', second: '2-digit' });
    }

}


@Pipe({
    name: 'prettyDateShort'
})
export class PrettyDateShortPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        const dueDate = new Date(value);
        const shortDate = `${dueDate.getDate()} / ${monthNames[dueDate.getMonth()]} / ${dueDate.getFullYear()}`;
        return shortDate;
        // return dueDate.toLocaleString('es-MX', { day: 'numeric', month: 'short', year: 'numeric' });
    }

}

@Pipe({
    name: 'prettyDateShortNumbers'
})
export class PrettyDateShortNumbersPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        const dueDate = new Date(value);
        const shortDate = `${dueDate.getDate()}/${dueDate.getMonth()+1}/${dueDate.getFullYear()}`;
        return shortDate;
    }

}

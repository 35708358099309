<div class="breadcrumbs-container">
    <section class="breadcrumbs">
        <div class="breadcrumbs-item" *ngFor="let item of breadcrumbItems; let i = index">
            <a *ngIf="(i < breadcrumbItems.length - 1)" [ngClass]="{ 'cursor-default':  item.title === ''}"
                [routerLink]="[item.url]" [queryParams]="item.query"
                routerLinkActive="router-link-active">{{item.title}} <i style="font-size: 4px;"
                    class="fad fa-circle"></i></a>
            <a class="last" *ngIf="i === breadcrumbItems.length - 1"
                [ngClass]="{ 'cursor-default':  item.title !== ''}">{{item.title}}</a>
        </div>
        <div class="breadcrumbs-counter" *ngIf="counter">
            <p>{{counter | number:'1.0-0' }} resultados</p>
        </div>
    </section>
</div>
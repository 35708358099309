import { Component, OnInit, Inject } from '@angular/core';
import { IConfirmDialog } from '../../interfaces/ConfirmDialog';
import { IBreadCrumbDialogParam, IBreadCrumbParam } from '../../interfaces/BreadcrumbDialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-warn-action',
  templateUrl: './dialog-warn-action.component.html',
  styleUrls: ['./dialog-warn-action.component.scss']
})
export class DialogWarnActionComponent implements OnInit, IBreadCrumbDialogParam {
  confirmText = '';
  bodyMessage = '';
  breadCrumbsParams: IBreadCrumbParam[];
  breadCrumbsWidth: string;
  constructor(
    private dialogRef: MatDialogRef<DialogWarnActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialog,
  ) { }

  ngOnInit() {
    if(this.data[0]) {
      if(this.data[0].confirmText) this.confirmText = this.data[0].confirmText;
      if(this.data[0].bodyMessage) this.bodyMessage = this.data[0].bodyMessage;

    }
    if (this.data[1]) {
      this.setBreadCrumbsBannerByParams(this.data[1]);
      this.breadCrumbsWidth = this.data[2];
    }
  }

  setBreadCrumbsBannerByParams(_breadCrumbsParams?: IBreadCrumbParam[]) {
    if (!!_breadCrumbsParams) {
      this.breadCrumbsParams = _breadCrumbsParams;
    }
  }


  public close() {
    this.dialogRef.close(false);
  }

  public confirm() {
    this.dialogRef.close(true);
  }



}

<div class="bread-crumb" [ngStyle]="{'width': breadCrumbsWidth}">
    <div class="bread-crumb-dialog">
        <app-breadcrumb-dialog [breadcrumbItems]="breadCrumbsParams"></app-breadcrumb-dialog>
    </div>
</div>

  <div class="dialog-form">
    <div class="dialog-form-header">
        <h1 mat-dialog-title>Advertencia</h1>
        <a (click)="close()" class="dialog-form-header-close">
            <i class="fad fa-times-circle"></i>
        </a>
    </div>
    <mat-dialog-content class="dialog-form-body bc-white">
        <p>{{bodyMessage}}</p>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-form-footer">
        <button mat-button mat-dialog-close class="dark1-alt" (click)="close()" type="button">Aceptar</button>
    </mat-dialog-actions>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

    transform(value: string, args?: any): any {
        const dueDate = new Date(value);

        return moment.utc(dueDate.toUTCString()).locale('es').fromNow();
    }

}

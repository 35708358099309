<div class="container condensed">
  <div class="errors">
    <img src="../../../assets/img/firma.png" height="60" />
    <div class="errors-card">
      <i class="fad fa-plug icon-warn icon-featured"></i>
      <h2>Error de conexión</h2>
      <h1>Ha fallado la conexión con el servidor</h1>
      <p>Puede que <strong class="warn">no tengas acceso a internet</strong>. Por favor, revisa tu conexión a internet o
        inténtalo más tarde.</p>
    </div>
  </div>
</div>
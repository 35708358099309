import { Component, OnInit, Inject } from '@angular/core';
import { IBreadCrumbDialogParam, IBreadCrumbParam } from '../../interfaces/BreadcrumbDialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-delete',
  templateUrl: './dialog-confirm-delete.component.html',
  styleUrls: ['./dialog-confirm-delete.component.scss']
})
export class DialogConfirmDeleteComponent implements OnInit, IBreadCrumbDialogParam {
  breadCrumbsParams: IBreadCrumbParam[];
  breadCrumbsWidth: string;
  constructor(
    private dialogRef: MatDialogRef<DialogConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit() {
    if (this.data[1]) {
      this.setBreadCrumbsBannerByParams(this.data[1]);
      this.breadCrumbsWidth = this.data[2];
    }
  }

  setBreadCrumbsBannerByParams(_breadCrumbsParams?: IBreadCrumbParam[]) {
    if (!!_breadCrumbsParams) {
      this.breadCrumbsParams = _breadCrumbsParams;
    }
  }


  public close(value) {
    this.dialogRef.close(value);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { IBreadCrumbParam } from '../../interfaces/BreadcrumbDialog';

@Component({
  selector: 'app-breadcrumb-dialog',
  templateUrl: './breadcrumb-dialog.component.html',
  styleUrls: ['./breadcrumb-dialog.component.scss']
})
export class BreadcrumbDialogComponent implements OnInit {
  @Input() breadcrumbItems: IBreadCrumbParam[];

  constructor() { }

  ngOnInit() {
  }



}

<!-- <div class="pager">
    <div class="pager-container" *ngIf="pager.pages && pager.pages.length">
        <div class="pager-item">
            <button mat-button (click)="setPage(1)" [ngClass]="{'disabled-alt':pager.currentPage === 1}"><i
                    class="fad fa-chevron-left"></i><i class="fad fa-chevron-left"></i></button>
        </div>
    </button>
    <button type="button" (click)="setPage( (pager.currentPage === 1) ? pager.currentPage : pager.currentPage - 1)"
    [ngClass]="pager.currentPage === 1 ? 'btn-disabled' : 'btn-secondary'" class="btn btn-xs" matTooltip="Página anterior"
    matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-left icon-xs"></i>
        </div>
    </button>
    <p class="paginator-text" *ngIf="(pager.currentPage * limit) <= (pager.totalItems)">{{((pager.currentPage - 1) * limit) + 1}} - {{pager.currentPage * limit}} de {{pager.totalItems}}</p>
    <p class="paginator-text"  *ngIf="(pager.currentPage * limit) > (pager.totalItems)">{{((pager.currentPage - 1) * limit) + 1}} - {{pager.totalItems}} de {{pager.totalItems}}</p>
    <button type="button" (click)="setPage( (pager.currentPage === pager.totalPages) ?  pager.currentPage : pager.currentPage + 1)"
    [ngClass]="pager.currentPage === pager.totalPages ? 'btn-disabled' : 'btn-secondary'" class="btn btn-xs" matTooltip="Siguiente página"
    matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-right icon-xs"></i>
        </div>
    </button>
    <button type="button" (click)="setPage(pager.totalPages)"
    [ngClass]="pager.currentPage === pager.totalPages ? 'btn-disabled' : 'btn-secondary'" class="btn btn-xs" matTooltip="Última página"
    matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-to-right icon-xs"></i>
        </div>
        <div class="pager-item">
            <button mat-button (click)="setPage(pager.totalPages)"
                [ngClass]="{'disabled-alt':pager.currentPage === pager.totalPages}"><i
                    class="fad fa-chevron-right"></i><i class="fad fa-chevron-right"></i></button>
        </div>
    </div>
</div> -->


<div class="btn-container" *ngIf="pager.pages && pager.pages.length">
    <button type="button" (click)="setPage(1)" [ngClass]="pager.currentPage === 1 ? 'btn-disabled' : 'btn-secondary'"
        class="btn btn-xs" matTooltip="Primera página" matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-to-left icon-xs"></i>
        </div>
    </button>
    <button type="button" (click)="setPage( (pager.currentPage === 1) ? pager.currentPage : pager.currentPage - 1)"
        [ngClass]="pager.currentPage === 1 ? 'btn-disabled' : 'btn-secondary'" class="btn btn-xs"
        matTooltip="Página anterior" matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-left icon-xs"></i>
        </div>
    </button>
    <p class="paginator-text" *ngIf="(pager.currentPage * limit) <= (pager.totalItems)">{{((pager.currentPage - 1) *
        limit) + 1}} - {{pager.currentPage * limit}} de {{pager.totalItems}}</p>
    <p class="paginator-text" *ngIf="(pager.currentPage * limit) > (pager.totalItems)">{{((pager.currentPage - 1) *
        limit) + 1}} - {{pager.totalItems}} de {{pager.totalItems}}</p>
    <button type="button"
        (click)="setPage( (pager.currentPage === pager.totalPages) ?  pager.currentPage : pager.currentPage + 1)"
        [ngClass]="pager.currentPage === pager.totalPages ? 'btn-disabled' : 'btn-secondary'" class="btn btn-xs"
        matTooltip="Siguiente página" matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-right icon-xs"></i>
        </div>
    </button>
    <button type="button" (click)="setPage(pager.totalPages)"
        [ngClass]="pager.currentPage === pager.totalPages ? 'btn-disabled' : 'btn-secondary'" class="btn btn-xs"
        matTooltip="Última página" matTooltipClass="tooltip-secondary">
        <div class="btn-xs-icon">
            <i class="fal fa-arrow-to-right icon-xs"></i>
        </div>
    </button>
</div>
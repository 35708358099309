import { Component, Inject, OnInit } from '@angular/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { IBreadCrumbDialogParam, IBreadCrumbParam } from '../../interfaces/BreadcrumbDialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-reject-comment',
  templateUrl: './dialog-reject-comment.component.html',
  styleUrls: ['./dialog-reject-comment.component.scss']
})
export class DialogRejectCommentComponent implements OnInit, IBreadCrumbDialogParam {
  confirmText = '';
  bodyMessage = '';
  dialogTitle = '';
  buttonIcon = '';
  dialogClass = '';
  dialogSize = '';
  breadCrumbsParams: IBreadCrumbParam[];
  breadCrumbsWidth: string;
  private required: Validators = Validators.compose([Validators.required]);
  public commentControl: UntypedFormControl = new UntypedFormControl(null, this.required);
  showButtonSaveLoader = false;
  constructor(
    private dialogRef: MatDialogRef<DialogRejectCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.info) {

      this.confirmText = this.data.info.confirmText || '';
      this.bodyMessage = this.data.info.bodyMessage || '';
      this.dialogTitle = this.data.info.dialogTitle || '';
      this.buttonIcon = this.data.info.buttonIcon || '';
      this.dialogClass = this.data.info.dialogClass || '';
      this.dialogSize = this.data.info.dialogSize || '';

    }
    if (this.data.bread) {
      this.setBreadCrumbsBannerByParams(this.data.bread);
      this.breadCrumbsWidth = this.data.breadWidth;
    }
  }

  setBreadCrumbsBannerByParams(_breadCrumbsParams?: IBreadCrumbParam[]) {
    if (!!_breadCrumbsParams) {
      this.breadCrumbsParams = _breadCrumbsParams;
    }
  }

  rejectAndSendComments() {
    this.commentControl.markAllAsTouched()
    if (this.commentControl.invalid) return
    this.showButtonSaveLoader = true;
    this.dialogRef.close(this.commentControl.value);

  }
  public close() {
    this.dialogRef.close(false);
  }

  public confirm() {
    this.dialogRef.close(true);
  }
}

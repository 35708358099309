import { Component, OnInit, Inject } from '@angular/core';
import { IConfirmDialog } from '../../interfaces/ConfirmDialog';
import { IBreadCrumbDialogParam, IBreadCrumbParam } from '../../interfaces/BreadcrumbDialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-action',
  templateUrl: './dialog-confirm-action.component.html',
  styleUrls: ['./dialog-confirm-action.component.scss']
})
export class DialogConfirmActionComponent implements OnInit, IBreadCrumbDialogParam {
  confirmText = '';
  bodyMessage = '';
  cancelText = '';
  dialogTitle = '';
  cancelClass = '';
  confirmClass = '';
  breadCrumbsParams: IBreadCrumbParam[];
  breadCrumbsWidth: string;
  constructor(
    private dialogRef: MatDialogRef<DialogConfirmActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { info: IConfirmDialog, breadCrumb: IBreadCrumbParam[], breadCrumbWidth: string },
  ) { }

  ngOnInit() {
    this.initDialogInfo()
    if (this.data.breadCrumb) {
      this.setBreadCrumbsBannerByParams(this.data.breadCrumb);
      this.breadCrumbsWidth = this.data.breadCrumbWidth;
    }
  }

  initDialogInfo() {
    Object.keys(this.data.info).forEach(key => {
      this[key] = this.data.info[key] || ''
    });
  }

  setBreadCrumbsBannerByParams(_breadCrumbsParams?: IBreadCrumbParam[]) {
    if (!!_breadCrumbsParams) {
      this.breadCrumbsParams = _breadCrumbsParams;
    }
  }


  public close() {
    this.dialogRef.close(false);
  }

  public confirm() {
    this.dialogRef.close(true);
  }



}

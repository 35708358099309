import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connection-error',
  templateUrl: './connection-error.component.html',
  styleUrls: ['./connection-error.component.scss']
})
export class ConnectionErrorComponent implements OnInit {

  isOnline = false;
  constructor(private router: Router) {
    setInterval(() => {
      if (navigator.onLine && !this.isOnline) {
        this.isOnline = true;
        router.navigate(['/']);
      }
    }, 1000);
  }

  ngOnInit() {
  }


}

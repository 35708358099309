import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/main/main.service';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
  providers: [
    SlugifyPipe
  ]
})
export class NavHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public categories = [];
  public visible = false;
  public imageUrl = 'assets/img/supplier-img.png';

  public topStyle = 60;

  private subscripionStyle: Subscription;

  constructor(private router: Router,
    private service: MainService,
    private slugifyPipe: SlugifyPipe,
    private sessionService: SessionService,

  ) { }
  ngAfterViewInit(): void {
    if (this.sessionService.getTermValue()) {
      if (this.topStyle < 120) {
        this.topStyle += 60;
      }
    }
    this.subscripionStyle = this.sessionService.termState
      .subscribe((state: number) => {


        if ((this.topStyle > 60 && state < 0) || (this.topStyle < 120 && state > 0)) {
          this.topStyle += state;
        }
      });
  }

  ngOnInit() {
    const promises = [];

    Promise
      .all(promises)
      .then(() => {

      })
      .catch((err) => {

      });





  }

  ngOnDestroy(): void {
    this.subscripionStyle.unsubscribe();
  }


  sendRoute(category) {
    let cateSlug = this.slugifyPipe.transform(category.name);
    const query = 'categories/' + cateSlug + '/' + category.id;
    this.visible = false;
    this.router.navigate([query], { queryParams: { page: '1' } });
  }

  sendRouteUrl(url) {
    this.visible = false;
    this.router.navigate([url]);
  }

  public getImgBackGround(image): { background: string } {
    const img = (image) ? image : this.imageUrl;
    return { background: `url( ${img} ) center center/cover no-repeat` };
  }



}

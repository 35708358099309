import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../core/utilities/permissions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private mainService: MainService
  ) { }

  compareStringWithStringArray(search: string, searchOptions: string[]) {
    search = this.lowerAndUnaccent(search)
    const optionsSize = searchOptions.length
    let i = 0;
    let match = false;
    while (!match && i < optionsSize) {
      match = this.lowerAndUnaccent(searchOptions[i]).includes(search)
      i++;
    }
    return match;
  }

  initPermissionsCheck(permissions, callback): void {
    const permissionsObject = {}
    Permissions.hasArrayPermissions(permissions, this.mainService).then(value => {
      permissions.forEach(permission => permissionsObject[permission] = value[permission])
      callback(permissionsObject);
    })
  }


  configureFormInputsCallback = (configs: { formControl: UntypedFormControl | UntypedFormGroup, callbackFn: Function, optionalConf?: { waitTime: number } }[]) => {
    configs.forEach(config => this.waitAndCalllbackFunction(config));
  }

  waitAndCalllbackFunction = (config: { formControl: UntypedFormControl | UntypedFormGroup, callbackFn: Function, optionalConf?: { waitTime: number } }) => {
    const { formControl, callbackFn, optionalConf } = config
    let waitTime = 0
    if (!this.isNullOrEmpty(optionalConf)) {
      waitTime = optionalConf.waitTime;
    }
    formControl.valueChanges.pipe(debounceTime(waitTime), distinctUntilChanged()).subscribe(
      result => callbackFn(result)
    )

  }

  lowerAndUnaccent(word: string) {
    return word.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public isNullOrEmpty(word: any) {
    return [undefined, null, "", {}].includes(word);
  }

  decimalKeyPress(event: any): void {
    const pattern = /[0-9\.\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.KeyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //----------------------------- PRIVATE FUNCTIONS -------------------------------------------------------------------------------------

}

<section class="page-title" [ngStyle]="getImgBackGround()">
  <div class="container">
    <div class="page-title-info">
      <h1>{{title}}</h1>
    </div>
    <div class="page-title-badges">
      <mat-chip-list #chipList aria-label="filter selection">
        <mat-chip *ngFor="let filter of selectedFilters" [selectable]="selectable" [removable]="removable"
          (removed)="remove(filter)">
          {{filter.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</section>
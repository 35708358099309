import { Injectable } from '@angular/core';

import { ComponentCanDeactivate } from './ComponentCanDeactivate';
import { Observable, of } from 'rxjs';

@Injectable()
export class CanDeactivateGuard  {


    // canDeactivate(component: ComponentCanDeactivate): boolean {
    //     if (!component.canDeactivate()) {
    //         if (confirm("¡Usted tiene cambios no guardados! Si te vas, tus cambios se perderán.")) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
        if (!component.canDeactivate()) {
            return component.checkStatus(component);
        } else {
            return of(true);
        }
    }
}



import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  @Input() heading: string;
  @Input() color: string;
  @Output() event: EventEmitter<string> = new EventEmitter<string>();

  public show = false;
  public defaultColors: string[] = [
    //red
    '#f44336','#e53935','#d32f2f','#c62828','#b71c1c',
    //pink
    '#E91E63','#D81B60','#C2185B','#AD1457','#880E4F',
    //purple
    '#9C27B0','#8E24AA','#7B1FA2','#6A1B9A','#4A148C',
    //deep purple
    '#673AB7','#5E35B1','#512DA8','#4527A0','#311B92',
    //indigo
    '#3F51B5','#3949AB','#303F9F','#283593','#1A237E',
    //blue
    '#2196F3','#1E88E5','#1976D2','#1565C0','#0D47A1',
    //light blue
    '#03A9F4','#039BE5','#0288D1','#0277BD','#01579B',
    //cyan
    '#00BCD4','#00ACC1','#0097A7','#00838F','#006064',
    //teal
    '#009688','#00897B','#00796B','#00695C','#004D40',
    //green
    '#4CAF50','#43A047','#388E3C','#2E7D32','#1B5E20',
    //light green
    '#8BC34A','#7CB342','#689F38','#558B2F','#33691E',
    //lime
    '#CDDC39','#C0CA33','#AFB42B','#9E9D24','#827717',
    //yellow
    '#FFEB3B','#FDD835','#FBC02D','#F9A825','#F57F17',
    //amber
    '#FFC107','#FFB300','#FFA000','#FF8F00','#FF6F00',
    //orange
    '#FF9800','#FB8C00','#F57C00','#EF6C00','#E65100',
    //deep orange
    '#FF5722','#F4511E','#E64A19','#D84315','#BF360C',
    //brown
    '#795548','#6D4C41','#5D4037','#4E342E','#3E2723',
    //grey
    '#9E9E9E','#757575','#616161','#424242','#212121',
    //blue grey
    '#607D8B','#546E7A','#455A64','#37474F','#263238'
  ];

  constructor() {
  }

  /**
   * Change color from default colors
   * @param {string} color
   */
  public changeColor(color: string): void {
    this.color = color;
    this.event.emit(this.color);
    this.show = false;
  }


  /**
   * Change color from input
   * @param {string} color
   */
  public changeColorManual(color: string): void {
    const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

    if (isValid) {
      this.color = color;
      this.event.emit(this.color);
    }
  }

  /**
   * Change status of visibility to color picker
   */
  public toggleColors(): void {
    this.show = !this.show;
  }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addWhiteSpaces'
})
export class AddWhiteSpacesPipe implements PipeTransform {

    transform(value: string, args: number): string {        
        let result = '';
        if (!value || !args) return result;
        const stringValue = value + '';
        for (var j = 0; j < stringValue.length; j++) {
            result = result + stringValue[j];
            if ((j + 1) % args === 0) {
                result = result + ' ';
            }
        }
        return result;
    }

    /**
 * Apply a format mask to the given string
 *
 * @param string
 * @param mask
 * @returns {string}
 */
    public applyFormatMask(string, mask): string {
        var formattedString = '';
        var numberPos = 0;
        for (var j = 0; j < mask.length; j++) {
            var currentMaskChar = mask[j];
            if (currentMaskChar === 'X') {
                var digit = string.charAt(numberPos);
                if (!digit) {
                    break;
                }
                formattedString += string.charAt(numberPos);
                numberPos++;
            } else {
                formattedString += currentMaskChar;
            }
        }
        return formattedString;
    }

}

<div class="bread-crumb" [ngStyle]="{'width': breadCrumbsWidth}">
    <div class="bread-crumb-dialog">
        <app-breadcrumb-dialog [breadcrumbItems]="breadCrumbsParams"></app-breadcrumb-dialog>
    </div>
</div>

<div class="dialog-form display-grid">
    <div class="dialog-form-header">
        <h1 class="mat-mdc-dialog-title" mat-dialog-title>{{ dialogTitle || 'Confirmar'}}</h1>

    </div>
    <mat-dialog-content class="dialog-form-body bc-white">
        <p>{{bodyMessage}}</p>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-form-footer">
        <button mat-button mat-dialog-close [ngClass]="cancelClass || 'dark1-alt'" (click)="close()"
            type="button">{{cancelText || 'Cancelar'}}</button>
        <button mat-button mat-dialog-close [ngClass]="confirmClass || 'warn-gradient '" (click)="confirm()"
            type="button">{{confirmText || 'Aceptar'}}</button>
    </mat-dialog-actions>
</div>
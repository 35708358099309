<div class="filtered-banner">
    <!-- <div class="filtered-banner-body">
        <p>Filtrado por el</p>
        <p [innerHTML]="filterBannerValue.filter_by"></p>
        <p>desde</p>
        <p><b>{{filterBannerValue.filter_from}}</b></p>
    </div> -->
    <div class="filtered-banner-body" [innerHTML]="urlQuery.body">

    </div>
    <div class="filtered-banner-action">
        <button mat-button class="transparent" (click)="onClickedBackBanner()" type="button">Volver</button>
        <button mat-button class="transparent" (click)="onClickedCloseBanner()" type="button">Quitar</button>
    </div>
</div>
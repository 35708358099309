import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filtered-banner',
  templateUrl: './filtered-banner.component.html',
  styleUrls: ['./filtered-banner.component.scss']
})
export class FilteredBannerComponent implements OnInit {
  @Input() urlQuery: any;
  @Output() clickedBack?: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedClose?: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  
  onClickedBackBanner() {
    this.clickedBack.emit(this.urlQuery);
  }

  onClickedCloseBanner() {
    this.clickedClose.emit(this.urlQuery);
  }


}

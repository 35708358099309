import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from 'src/app/session.service';
import { Subscription } from 'rxjs';
import { LoaderState } from '../../interfaces/Loader';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {
  show = false;
  await = true;
  private subscription: Subscription;
  constructor(private sessionService: SessionService) { }

  ngOnInit() {
    this.subscription = this.sessionService.loaderState
      .subscribe(async (state: LoaderState) => {
        if (!state.show) {
          await this.delay(800);
          this.show = false;
        } else {

          this.show = state.show;
        }
        /*
        if (state.show) {
          /*
          // this.await = state.show;

          setTimeout(function () {
            if(this.await)
              this.show = state.show;
          }, 500);
        } else {

          this.await = state.await;
          this.show = state.show;
        }
        */
      });
  }
  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private showLoader(): void {
    this.sessionService.showLoader();
  }
  private hideLoader(): void {
    this.sessionService.hideLoader();
  }

}

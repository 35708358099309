import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { Permissions } from 'src/app/core/utilities/permissions';
import { IAccount } from '../../interfaces/account';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { SessionService } from 'src/app/session.service';
import { MainService } from 'src/app/main/main.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/common/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() user: IAccount;
  @Output() userLogout: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('customNotification', { static: false }) customNotificationTmpl;
  public permission = Permissions.permissions;
  public requisition_active = false;
  public requisition_draft_active = false;
  public asset_active = false;

  public imageUrl = 'assets/img/dual-flat.png';


  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private cookieService: CookieService,
    private sessionService: SessionService,
    private mainService: MainService,
    private utilsService : UtilsService
  ) { }

  ngOnInit(): void {
    if (this.cookieService.get('token')) {
      this.initPermissionsCheck();
    } else {
      this.user = null;
    }
  }

  private initPermissionsCheck() {
    const permissions = [
      'notification_list',
      'whoami_full',
      'back_office_organizations_list',
      'back_office_businesses_list',
    ];
    this.utilsService.initPermissionsCheck(permissions, result => this.permission = result)
  }

  logout() {
    this.authService.logout().then(result => {
      this.cookieService.delete('token', '/');
      this.cookieService.delete('token');
      this.cookieService.deleteAll();
      this.cookieService.deleteAll('/', '/');
      this.user = null;
      this.cookieService.deleteAll();

      this.router.navigate(['/auth']);
    });
  }


  GoLogin() {
    this.cookieService.deleteAll();
    this.router.navigate(['/auth']);
  }

  public getImgBackGround(): { background: string } {
    if (!this.user) {
      let img = this.imageUrl;
      return { background: `url( ${img} ) center center/cover no-repeat #ffffff` };
    }
    let img = (!this.user.image) ? null : this.user.image;
    img = (!this.user.image) ? null : img.slice(0, (img.length - 4)) + environment.images.small + img.slice(img.length - 4 + Math.abs(0));
    img = (!img ? this.imageUrl : `${environment.images.url}/${img}`);

    return { background: `url( ${img} ) center center / cover no-repeat #ffffff` };
  }

}

<div class="container condensed">
  <div class="errors">
    <img src="../../../assets/img/firma.png" height="60" />
    <div class="errors-card">
      <i class="fad fa-ban icon-warn icon-featured"></i>
      <h2>Error 401</h2>
      <h1>Acceso restringido</h1>
      <p>Lo sentimos, <strong class="warn">no tienes permisos</strong> para acceder a esta sección. Por favor, contacta
        a tu administrador.</p>
      <button mat-button class="dark2" [routerLink]="['/']">Volver a inicio</button>
    </div>
  </div>
</div>
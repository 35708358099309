import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import {log} from 'util';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberOnly]'
})

export class NumberOnlyDirective implements OnChanges {
  private regexString = '(^-?[0-9]{0,@integer@})+(\\.[0-9]{0,@decimal@}){0,1}$';
  private regexInt = '^[0-9]{0,@integer@}$';
  private float: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Meta', 'Control', 'v', 'c'];
  private oldValue: string;

  // Inputs
  @Input() integer = false;
  @Input() appNumberOnly: string;
  @Input() eqZero = false;
  @Input() min = 0;

  // Prevent pasting (secondary click) to avoid numbers that were not validated
  @HostListener('contextmenu', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    return false;
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    if (!String(this.oldValue).length) { this.oldValue = this.control.control.value; }

    if (!this.specialKeys.includes(event.key) && isNaN(Number(event.key)) && event.key !== '.') {
      event.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  inputChanged(event) {
    const inputValue = this.control.control.value;

    if (String(inputValue) && !String(inputValue).match(this.float)) {
      // this.el.nativeElement.value = this.oldValue;
      this.control.control.setValue(this.oldValue);
      return;
    }

    this.oldValue = this.el.nativeElement.value;
  }

  @HostListener('blur', ['$event'])
  onblur(event) {
    this.control.control.setValue((event.target.value && event.target.value < this.min) ? '' : event.target.value);
  }

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {
    const appNumberOnly = el.nativeElement.attributes.appNumberOnly.value;
    const array = appNumberOnly ? appNumberOnly.split(',') : '3,2'.split(',');

    this.oldValue = this.el.nativeElement.value;

    this.float = new RegExp(this.regexString
      .replace('@integer@', array[0])
      .replace('@decimal@', array[1]), 'g');

    this.float = array[1] === '0' ? new RegExp(this.regexInt.replace('@integer@', array[0]), 'g') : this.float;
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-pager',
  templateUrl: './list-pager.component.html',
  styleUrls: ['./list-pager.component.scss']
})
export class ListPagerComponent implements OnInit {
  @Input() pager: any;
  @Input() total_count: any;
  @Input() limit?: number = 10;
  @Output() setterPage = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  setPage(page: number) {
    this.setterPage.emit(page);
  }

}

<div class="container condensed">
  <div class="errors">
    <img src="../../../assets/img/firma.png" height="60" />
    <div class="errors-card">
      <i class="fad fa-exclamation-circle icon-warn icon-featured"></i>
      <h2>Error 404</h2>
      <h1>Página no encontrada</h1>
      <p>Lo sentimos, <strong class="warn">no se puede acceder</strong> a la página que estás buscando. Por favor
        verifica
        la URL.</p>
      <button mat-button class="dark2" [routerLink]="['/']">Volver a inicio</button>
    </div>
  </div>
</div>
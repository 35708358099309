import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogsUtilsService {

  constructor(
    private dialog: MatDialog
  ) { }

  /**
   * Open dialog with specifict type
   * @param type : DialogTypes
   * @param data : any should be IConfirmDialog
   * @param color : Colors
   * @returns: Promise<{data: boolean}>

  openDialog(type: DialogTypes, data: any): Promise<{data: boolean}> {
    switch (type) {
      case DialogTypes.ConfirmDialog:
        // return this.createDialog(ConfirmDialogComponent, data);
      // TODO: Add an other type of Dialogs
      default:
      // return this.createDialog(ConfirmDialogComponent, data);
    }

  }

  */

  /**
   * Create a dialog with specifict type
   * @param type ComponentRef
   * @param data : IConfirmDialog
   * @param color: Colors
   * @returns: Promise<{data: boolean}>
   */
  private createDialog(type: any, data: any): Promise<{data: boolean}> {
    const dialog = this.dialog.open(type, {
      width: '480px',
      disableClose: false,
      autoFocus: false,
      data: data
    });
    return dialog.afterClosed().toPromise();
  }

}

export enum DialogTypes {
  ConfirmDialog
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConnectionErrorComponent } from './pages/connection-error/connection-error.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./main/main.module').then(mod => mod.MainModule) },
  { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then(mod => mod.AuthenticationModule) },
  { path: '', loadChildren: () => import('./authentication/authentication.module').then(mod => mod.AuthenticationModule) },
  { path: 'connection-error', component: ConnectionErrorComponent, data: { clean_search: true, title: 'Error de Conexión' } },
  { path: 'access-denied', component: AccessDeniedComponent, data: { clean_search: true, title: 'AccessDenied' } },
  { path: 'not-found', component: NotFoundComponent, data: { clean_search: true, title: 'NotFound' } },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

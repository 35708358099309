// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const path = 'https://api-qa.agricompra.com/v1'

export const environment = {
  label: "local",
  production: false,
  hmr: false,
  api: {
    url: path,
    upload: `${path}/back_office`
  },
  images: {
    url: 'https://storage.googleapis.com/agricompra-com-media',
    thumbnail: '_thumbnail',
    small: '_small',
    medium: '_medium',
    large: '_large'
  },

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

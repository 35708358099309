import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
      MatButtonModule,
      MatInputModule,
      MatSelectModule,
      MatIconModule,
      MatListModule,
      MatMenuModule,
      MatSidenavModule,
      MatToolbarModule,
      MatTabsModule,
      MatCardModule,
      MatAutocompleteModule,
      MatDialogModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatCheckboxModule,
      MatChipsModule,
      MatSlideToggleModule,
      MatButtonToggleModule,
      MatExpansionModule,
      MatRadioModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      MatStepperModule,
      MatBottomSheetModule
    ],
    exports: [
      MatButtonModule,
      MatInputModule,
      MatSelectModule,
      MatIconModule,
      MatListModule,
      MatMenuModule,
      MatSidenavModule,
      MatToolbarModule,
      MatTabsModule,
      MatCardModule,
      MatAutocompleteModule,
      MatDialogModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatCheckboxModule,
      MatChipsModule,
      MatSlideToggleModule,
      MatButtonToggleModule,
      MatExpansionModule,
      MatRadioModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      MatStepperModule,
      MatBottomSheetModule

    ],
    providers: []
})
export class MaterialModule {}

<footer class="footer">
  <div class="container">

    <div class="footer-copyright">
      <img src="../../../../assets/img/logo-backoffice.png" height="40">
      <p>© 2020 agricompra | Todos los derechos reservados | Precios expresados en moneda nacional (MXN) y dolares
        estadounidenses (USD). Al momento de cotizar puede haber variaciones de precios a criterio de cada proveedor.
      </p>
    </div>
  </div>
</footer>
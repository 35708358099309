import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateDecimals'
})
export class TruncateDecimalsPipe implements PipeTransform {

  /**
   * Transforms to number and truncates up to 2 decimal places
   * (required by business logic)
   * @param value : The number to be transformed and truncated
   */
  transform(value: any, decimalPlaces: number = 2): number {
    if ( Number.isNaN(value)) {
      return 0;
    }

    if ( !(!!String(value).match(/\./g)) ) {
      return Number(value);
    }

    return Number(Number(value).toFixed(decimalPlaces));
  }

}

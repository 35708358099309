<div class="navbar-fix"></div>
<nav class="navbar" [ngStyle]="{'top': topStyle + 'px'}">
  <div class="container">
    <div class="mega-menu">
      <a (click)="visible = !visible">Categorias <i class="fad fa-chevron-down"></i></a>
      <div class="mega-menu-content" *ngIf="visible">
        <div class="mega-menu-content-inner">
          <a class="mega-menu-collection" *ngFor="let cat of categories" >
            <div class="mega-menu-collection-img"  (click)="sendRoute(cat)" [ngStyle]="getImgBackGround(cat.image)">
              <h1>{{cat.name}}</h1>
            </div>
          </a>
          <div class="mega-menu-collection-step">
            <div class="mega-menu-collection-step">
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="desktop" [routerLink]="['//collections/DescubrimientoSemanal/8']" routerLinkActive="router-link-active">Descubrimiento Semanal</a>
    <a class="desktop" [routerLink]="['/stores']" routerLinkActive="router-link-active">Tiendas</a>
    <a class="desktop" [routerLink]="['/subscription']" routerLinkActive="router-link-active">Vende</a>
    <a class="desktop" [routerLink]="['/help']" routerLinkActive="router-link-active">Ayuda</a>
    <a class="desktop" matTooltip="Visitar comunidad en Facebook" href="https://www.facebook.com/groups/agricompra"
      target="_blank">Comunidad</a>
  </div>
</nav>
import { MainService } from 'src/app/main/main.service';
import { DataService } from 'src/app/data.service';

export class Permissions {
    /***
    *** Use camelCase for url permissions
    *** User snake_case for action permissions
    **/
    static permissions = {
        permissionDashboardNotifications: false,
        permission_back_office_whoami: false,
        permission_back_office_signin: false,
        permission_back_office_whoami_full: false,
        permission_back_office_signout: false,
        permission_back_office_check_reset: false,
        permission_back_office_BackOfficeUser_update_image: false,
        permission_back_office_BackOfficeUser_list: false,
        permission_back_office_BackOfficeUser_read: false,
        permission_back_office_BackOfficeUser_update: false,
        permission_back_office_upload_batch_file: false,
        permission_back_office_download_template: false,
        permission_back_office_list: false,
        permission_back_office_upload_scatter_file: false,
        permission_back_office_batch_list: false,
        back_office_organizations_list: false,
        verify_valid: false,
        verify_reject: false,
        verify_download_files: false,
        back_office_businesses_list: false,
    };



    constructor(
        private mainService: MainService,
        private dataService: DataService,

    ) {

    }

    static getDefaultsPermissions(): any {
        return this.permissions;
    }

    static hasPermission(name: string, service: MainService): Promise<{ exist: boolean; }> {
        // return this.dataService.get(url).toPromise();
        return service.getPermissionsByName(name);
    }

    static hasArrayPermissions(permissions: string[], service: MainService): any {
        const permission = {
            permission: permissions
        };
        return service.postPermissionsByArrayName(permission);
    }
}

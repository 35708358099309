import { Component, OnInit, Input } from '@angular/core';
import { IBreadcrumbFilter } from 'src/app/core/components/breadcrumb-filter/breadcrumb-filter.component';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbItems: IBreadcrumbFilter[];
  @Input() counter?: number[];

  public breadcrumbs: string;
  constructor() { }

  ngOnInit() {
  }

}
